/* ALL DEVICES  */

.header {
    background-color: #040F28;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* MEDIUM DEVICES */

@media only screen and (min-width: 993px) {
    .header {
        height: 140px;
    }
    .logo{
        width: 120px
    }
}

/* MEDIUM DEVICES */

@media only screen and (max-width: 992px) {
    .header {
        height: 120px;
    }
    .logo{
        width: 100px;
    }
}
/* PHONES */

@media only screen and (max-width: 600px) {
    .header {
        height: 100px;
    }
    .logo{
        width: 75px;
    }
}