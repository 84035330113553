/* PHONES */

.products {
    display: flex;
    justify-content: center;
    padding: 10px;
}
.product-container {
    width: 97%;
    margin: 80px auto;
}
.btn-view-all-container {
    margin-top: 40px;
    text-align: center;
}
.btn {
    width: 190px;
    height: 50px;
}
a {
   text-decoration: none; 
}

@media only screen and (min-width: 1390px) {
    .product-container {
        width: 1120px!important;
        margin: 80px auto!important;
    }
}
@media all and (max-width:1390px) and (min-width:901px) {
    .product-container {
        width: 80%!important;
        margin: 80px auto!important;
    }
    .products {
        padding: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .product-container {
        margin: 40px auto!important;
        width: 65%;
    }
    .btn {
        width: 141px;
        height: 42px;
    }
    .btn-view-all-container {
        margin-top: 20px;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    .product-container {
        width: 97%;
        margin: 40px auto!important;
        padding: 5px;
    }
}
